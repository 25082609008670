import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home', // 首页
    component: Home,
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '店铺'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'), // 市场页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '市场'
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/Order.vue'), // 消息页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '订单'
    }
  },
  {
    path: "/refundorderone",
    name: "RefundOrderOne",
    component: () => import("../views/refund/RefundOrderOne.vue"), // 选择售后类型
    meta: {
      keepAlive: false,
      showbottom: true,
      requireLogin: true, // 是否必须登陆
      pagetitle: '售后类型'
    }
  },
  {
    path: "/serviceDetail",
    name: "serviceDetail",
    component: () => import("../views/refund/serviceDetail.vue"), // 售后详情
    meta: {
      keepAlive: false,
      showbottom: true,
      requireLogin: true, // 是否必须登陆
      pagetitle: '售后详情'
    }
  },
  {
    path: '/myhome',
    name: 'Myhome',
    component: () => import('../views/Myhome.vue'), // 我的页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '我的'
    }
  },
  {
    path: '/shareshop',
    name: 'Shareshop',
    component: () => import('../views/Shareshop.vue'), // 分享店铺
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '分享店铺'
    }
  },
  {
    path: '/shopinfo',
    name: 'Shopinfo',
    component: () => import('../views/shop/Shopinfo.vue'), // 商户信息
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '店铺信息'
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/shop/Account.vue'), // 账户资产页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '账户资金'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'), // 登陆页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: false, // 是否必须登陆
      pagetitle: '登录'
    }
  },
  {
    path: '/setpwd',
    name: 'Setpwd',
    component: () => import('../views/Setpwd.vue'), // 找回密码页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: false, // 是否必须登陆
      pagetitle: '找回密码'
    }
  },
  {
    path: '/earning',
    name: 'Earning',
    component: () => import('../views/shop/Earning.vue'), // 账户资产页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '', // 已经在页面中定义标题了
    }
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('../views/Member.vue'), // 客户管理页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '客户管理',
    }
  },
  {
    path: '/income',
    name: 'Income',
    component: () => import('../views/order/Income.vue'), // 订单明细页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '订单明细',
    }
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import('../views/shop/Withdraw.vue'), // 提现记录页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '提现记录',
    }
  },
  {
    path: '/goodslist',
    name: 'Goodslist',
    component: () => import('../views/goods/List.vue'), // 商品管理页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '商品管理',
    }
  },
  {
    path: '/goodsedit',
    name: 'GoodsEdit',
    component: () => import('../views/goods/Edit.vue'), // 商品改价页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '商品改价',
    }
  },
  {
    path: '/ordersearch',
    name: 'OrderSearch',
    component: () => import('../views/order/Search.vue'), // 订单搜索页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '订单搜索',
    }
  },
  {
    path: '/orderdetail',
    name: 'OrderDetail',
    component: () => import('../views/order/Detail.vue'), // 订单详情页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '订单详情',
    }
  },
  {
    path: '/tixian',
    name: 'Tixian',
    component: () => import('../views/shop/ComissionTixian.vue'), // 提现页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '提现',
    }
  },
  {
    path: '/tixiansuccess',
    name: 'TixianSuccess',
    component: () => import('../views/shop/TixianResult.vue'), // 提现结果
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '提现结果',
    }
  },
  {
    path: '/earningorder',
    name: 'Earningorder',
    component: () => import('../views/shop/EarningOrder.vue'), // 每笔提现记录提现的订单列表
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '提现明细',
    }
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('../views/shop/Statistics.vue'), // 每笔提现记录提现的订单列表
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '店铺数据',
    }
  },
  {
    path: '/shantuantuan',
    name: 'Shantuantuan',
    component: () => import('../views/shantuantuan/Shantuantuan.vue'), // 闪团团收益
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '闪团团收益',
    }
  },
  {
    path: '/opentuan',
    name: 'Opentuan',
    component: () => import('../views/shantuantuan/opentuan.vue'), // 开团申请
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '开团申请',
    }
  },
  {
    path: '/membersort',
    name: 'MemberSort',
    component: () => import('../views/order/membersort.vue'), // 开团申请
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '销售排行',
    }
  },
  {
    path: '/Liebian',
    name: 'Liebian',
    component: () => import('../views/shop/Liebian.vue'), // 裂变排行
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '裂变排行',
    }
  },
  {
    path: '/zhongzi_list',
    name: 'ZhongziList',
    component: () => import('../views/shop/zhongzixiaofeilist.vue'), // 裂变排行
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '种子兑换列表',
    }
  },
  {
    path: '/set_zhongzi',
    name: 'SetZhongzi',
    component: () => import('../views/shop/setzhongzixiaofei.vue'), // 裂变排行
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '种子兑换设置',
    }
  },
  {
    path: '/edit_shop',
    name: 'EditShop',
    component: () => import('../views/shop/edit_shop.vue'), // 裂变排行
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '装修首页',
    }
  },
  {
    path: '/goods_price_edit',
    name: 'GoodsPriceEdit',
    component: () => import('../views/goods/goods_price_edit.vue'), // 裂变排行
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '全局改价',
    }
  },
  {
    path: '/goodsaddprice',
    name: 'Goodsaddprice',
    component: () => import('../views/Goodsaddprice.vue'),
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '新品默认加价规则',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition  //游览器返回记住位置
    } else {
      return { x: 0, y: 0 } //滚动到顶
    }
  }
})

export default router
