<template>
  <div id="app" ref="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <div id="nav">
      <van-tabbar v-model="active" v-if="$route.meta.TabbarShow" :safe-area-inset-bottom="true" z-index="10" placeholder >
        <van-tabbar-item>
          <router-link replace :to="{ name: 'Home' }">
            <van-icon name="wap-home-o" />店铺
          </router-link>
        </van-tabbar-item>
        <van-tabbar-item>
          <router-link replace :to="{ name: 'About' }">
            <van-icon name="apps-o" />市场
          </router-link>
        </van-tabbar-item>
        <van-tabbar-item @click="showqiyu" v-if="false">
          <van-icon
            name="service-o"
            :badge="unreadmsgNum > 0 && islogin ? unreadmsgNum : ''"
          />客服
        </van-tabbar-item>
        <van-tabbar-item>
          <router-link replace :to="{ name: 'Order', query: { xcx: this.xcx } }"> 
            <van-icon
              name="orders-o"
            />订单
          </router-link>
        </van-tabbar-item>
        <van-tabbar-item>
          <router-link replace :to="{ name: 'Myhome', query: { xcx: this.xcx } }">
            <van-icon name="contact" />我的
          </router-link>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      shareid: 0,
      merchid: 0,
      active: 0,
      uid: 0,
      msgNum: 0,
      islogin: false,
      unreadmsgNum: 0,
      userinfo: {},
      xcx: 0
    }
  },
  mounted() {
    this.xcx = this.$route.query.xcx ? this.$route.query.xcx : 0;
  },
  methods: {
    // showqiyu() {
    //   let _that = null;
    //   _that = this;
    //   _that.unreadmsgNum = 0;

    //   if (this.merchid == 0 || this.showkefu == 1) {
    //     if (window.qiyuSdkReady) {
    //       _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
    //       _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
    //       _that.$axios
    //         .post(_that.$store.state.domain + "shopsmobile/user/get_info")
    //         .then(function(response) {
    //           if (response.data.data.id > 0) {
    //             _that.userinfo = response.data;
    //             let level = 1;
    //             let levelname = "普通会员";
    //             if (_that.userinfo.level == '6') {
    //               level = 2;
    //             }else if (_that.userinfo.level == '1') {
    //               level = 3;
    //             }else if (_that.userinfo.level == '14') {
    //               level = 4;
    //             }else if (_that.userinfo.level == '5') {
    //               level = 5;
    //             }
    //             if (_that.userinfo.levelname) {
    //               levelname = _that.userinfo.levelname;
    //             }
    //             window.ysf('config', {
    //                 uid: _that.userinfo.memberid,      // 用户Id
    //                 name: _that.userinfo.nickname,          // 用户名称
    //                 level : level, // vip级别 5囤货， 14 CEO，1 顶级，6 特级
    //                 data: JSON.stringify([
    //                   {"index": 0, "key":"avatar","label":"头像","value":_that.userinfo.avatar},
    //                   {"index":1, "key":"agent_code", "label":"邀请码", "value":_that.userinfo.agent_code},
    //                   {"index":2, "key":"level", "label":"等级", "value":levelname},
    //                   {"index":3, "key":"mobile", "label":"手机号", "value":_that.userinfo.mobile},
    //                   {"index":4, "key":"shopid", "label":"商户id", "value":_that.merchid},
    //                   {"index":5, "key":"shopname", "label":"商户名称", "value":_that.merchname},
    //                   {"index":6, "key":"origin", "label":"来源", "value":"手机版联盟店"},
    //                 ]),
    //                 success: function(){     // 成功回调
    //                   window.ysf("open");
    //                 },
    //                 error: function(){       // 错误回调
    //                   // handle error
    //                 }
    //             })
    //           }else{
    //             _that.$toast("暂未开通")
    //           }
    //         })
    //         .catch(function(error) {
    //           console.log(error);
    //         });
    //     }else{
    //       this.$toast("页面还未加载完，稍等一下");
    //     }
    //   }
    // }
  },
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  height: 100%;
}
.van-cell__title,
.van-cell__value {
  -webkit-box-flex: none;
  -webkit-flex: none;
  flex: none;
}
.van-nav-bar {
  height: 50px;
  line-height: 50px;
}
.van-nav-bar .van-icon,
.van-nav-bar__text {
  color: #333;
}
#nav {
  a {
    display: block;
    font-weight: bold;
    color: #333;
    font-size: 12px;
    font-weight: normal;

    &.router-link-exact-active {
      color: #ee0a24;
    }
  }
  .van-tabbar--fixed {
    right: 0;
  }
  .van-tabbar-item__icon {
    display: none;
  }
  .van-tabbar-item__text {
    width: 100%;
    padding: 5px 0;
  }
  .van-tabbar-item__text .van-icon {
    display: block;
    margin-bottom: 5px;
    font-size: 20px;
  }
  .van-info {
    right: 20%;
  }
  
}
</style>
