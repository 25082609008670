import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import axios from "axios";
import qs from "qs";
import VueLocalStorage from "vue-localstorage";
import QRCode from 'qrcodejs2';
import html2canvas from 'html2canvas'
import VueClipboard from 'vue-clipboard2'


import {
  Icon,
  Dialog,
  Notify,
  Toast,
  Lazyload,
  Col,
  Row,
  Tag,
  NavBar,
  CountDown,
  Button,
  Divider,
  Form,
  Field,
  Cell,
  Card,
  Image as VanImage,
  List,
  Sidebar,
  SidebarItem,
  Calendar,
  SubmitBar,
  Stepper,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Swipe,
  SwipeItem,
  Loading,
  Search,
  Grid,
  GridItem,
  CellGroup,
  Tabbar,
  TabbarItem,
  Sticky,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Sku,
  Popup,
  Uploader,
  Picker,
  DatetimePicker,
  Area,
  ImagePreview,
  AddressList,
  AddressEdit,
  Empty,
  Tab,
  Tabs,
  Step,
  Steps,
  NoticeBar,
  DropdownMenu,
  DropdownItem,
  ActionSheet,
  PullRefresh,
  Rate,
  Switch,
  Progress
} from "vant";

Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Notify);
Vue.use(Toast);
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(Col);
Vue.use(Row);
Vue.use(Tag);
Vue.use(NavBar);
Vue.use(CountDown);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Form);
Vue.use(Field);
Vue.use(Cell);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(List);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Calendar);
Vue.use(SubmitBar);
Vue.use(Stepper);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Loading);
Vue.use(Search);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(CellGroup);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Sticky);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Sku);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Area);
Vue.use(ImagePreview);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(Empty);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Step);
Vue.use(Steps);
Vue.use(NoticeBar);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ActionSheet);
Vue.use(PullRefresh);
Vue.use(Rate);
Vue.use(Switch);
Vue.use(Progress);

Vue.use(VueLocalStorage);
axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.QRCode = QRCode;
Vue.prototype.$html2canvas = html2canvas;

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard)

/**
 * 判断该路由是否需要登录权限
 * 根据当前用户的登录信息M_ISLOGIN是否存在
 */

router.beforeEach((to, from, next) => {
  console.log(to, from);
  if (to.matched.some(record => record.meta.pagetitle)) {
    document.title = to.meta.pagetitle;
  }

  if (to.matched.some(record => record.meta.requireLogin)) {
    // 是否登录标识
    if (localStorage.getItem("SHOP_LOGIN")) {
      if (localStorage.getItem("SHOP_LOGIN") === "false") {
        // 已经退出登录
        if (to.query.logincode && to.query.logincode.length > 0) {
          // console.log(to.query.logincode);
          next(); //已经登录，继续执行
        }else {
          next({
            name: "Login", //已经登出,Go登录页
            query: {
              logincode: to.query.logincode ? to.query.logincode : ""
            }
          });
        }
      } else {
        next(); //已经登录，继续执行
      }
    } else {
      // 一次都没有登录过
      if (to.query.logincode && to.query.logincode.length > 0) {
        // console.log(to.query.logincode);
        next(); //已经登录，继续执行
      }else {
        next({
          name: "Login", //第一次登录 Go录页
          query: {
            logincode: to.query.logincode ? to.query.logincode : ""
          }
        });
      }
    }
  } else {
    next(); //不需登录 继续执行
  }
});


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
