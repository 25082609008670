<template>
  <div class="home" :style="'min-height:' + screeHeight + 'px;'">
    <div class="header">
      <van-cell :border="false" center>
        <template #icon>
          <van-image
            width="60"
            height="60"
            lazy-load
            :src="info.logo"
            fit="cover"
            round
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </template>
        <template #title>
          <div class="shop_name" @click="shopinfo">
            <div class="van-ellipsis">{{info.merchname}}</div><van-icon name="arrow" />
          </div>
          <div class="shop_data" @click="copy(info.bianhao)">
            店铺码：{{info.bianhao}} 
            <van-tag plain color="#F8FFFF">复制</van-tag>
          </div>
        </template>
        <template #right-icon>
          <div class="right_list">
            <van-grid :column-num="1" :border="false" icon-size="40">
              <van-grid-item @click="sharepage" icon="qr" text="分享店铺" />
            </van-grid>
          </div>
        </template>
      </van-cell>
      <div class="renzheng" v-if="false">
        <van-cell @click="gorenzheng" icon="volume" title="店铺需要实名认证，请实名认证后操作。" is-link></van-cell>
      </div>
    </div>
    <div class="order_data_main">
      <!-- 联盟店 -->
      <div class="shop_day_data" v-if="info.groupid == 1 || info.groupid == 12">
        <van-cell :border="false" title="今日数据" :value="order.day" is-link @click="tolist"></van-cell>
        <van-grid :column-num="3" :border="false">
          <van-grid-item icon="photo-o" @click="tolist">
            <template #icon>
              <div class="grid_item_title">支付金额</div>
              <div class="grid_item_num">&yen;{{order.today && order.today.price ? order.today.price : 0}}</div>
            </template>
            <template #text>
              <div class="grid_item_text">昨日 &yen;{{order.yesday && order.yesday.price ? order.yesday.price : 0}}</div> 
            </template>
          </van-grid-item>
          <van-grid-item icon="photo-o" @click="tolist">
            <template #icon>
              <div class="grid_item_title">订单数</div>
              <div class="grid_item_num">{{order.today && order.today.num ? order.today.num : 0}}</div>
            </template>
            <template #text>
              <div class="grid_item_text">昨日 {{order.yesday && order.yesday.num ? order.yesday.num : 0}}</div> 
            </template>
          </van-grid-item>
          <van-grid-item :to="{ name: 'Statistics' }">
            <template #icon>
              <div class="grid_item_title">新增访客</div>
              <div class="grid_item_num">{{memberNum.newy ? memberNum.newy : 0}}</div>
            </template>
            <template #text>
              <div class="grid_item_text">昨日 {{memberNum.twonewy ? memberNum.twonewy : 0}}</div> 
            </template>
          </van-grid-item>
          <van-grid-item icon="photo-o" @click="tolist">
            <template #icon>
              <div class="grid_item_title">预估佣金收益</div>
              <div class="grid_item_num">&yen;{{order.today && order.today.yongjin ? order.today.yongjin : 0}}</div>
            </template>
            <template #text>
              <div class="grid_item_text">昨日 &yen;{{order.yesday && order.yesday.yongjin ? order.yesday.yongjin : 0}}</div> 
            </template>
          </van-grid-item>
          <van-grid-item icon="photo-o" @click="tolist">
            <template #icon>
              <div class="grid_item_title">预估差价收益</div>
              <div class="grid_item_num">&yen;{{order.today && order.today.earnings ? order.today.earnings : 0}}</div>
            </template>
            <template #text>
              <div class="grid_item_text">昨日 &yen;{{order.yesday && order.yesday.earnings ? order.yesday.earnings : 0}}</div> 
            </template>
          </van-grid-item>
          <van-grid-item :to="{ name: 'Statistics' }">
            <template #icon>
              <div class="grid_item_title">新增会员</div>
              <div class="grid_item_num">{{memberNum.newh ? memberNum.newh : 0}}</div>
            </template>
            <template #text>
              <div class="grid_item_text">总计：{{memberNum.allh ? memberNum.allh : 0}}</div> 
            </template>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
      
    <div class="shop_goods_switch">
      <van-cell :border="false" title-style="font-weight: bold;font-size: 16px;" title="店铺工具" is-link></van-cell>
      <van-grid :column-num="2" class="switch_btn_list">
        
        <van-grid-item use-slot>
          <van-cell center >
            <template #title>
              <div class="title_class">
                <span style="margin-right: 5px;">上架全部商品</span>
                <van-icon name="question" @click="showAllgoodsUp" />
              </div>
            </template>
            <template #right-icon>
              <!-- <van-switch
                v-model="isallgoods"
                @change="setallgoodsup"
                :active-value="2"
                :inactive-value="1"
                active-color="#C82E30"
                size="20"
                :loading="goodsallupload"
                :disabled="isallgoods == 2 ? true : false"
              /> -->
              <van-button size="mini" @click="setallgoodsup(2)" type="primary" round style="padding: 0 8px;">刷新</van-button>
            </template>
          </van-cell>
        </van-grid-item>
        <van-grid-item use-slot v-if="false">
          <van-cell center>
            <template #title>
              <div class="title_class">
                <span style="margin-right: 5px;">商品自动上下架</span>
                <van-icon name="question" @click="showgoodsChecked" />
              </div>
            </template>
            <template #right-icon>
              <van-switch
                v-model="goodschecked"
                @change="setgoodschecked"
                :active-value="1"
                :inactive-value="0"
                active-color="#C82E30"
                size="20"
                :loading="goodscheckedload"
              />
            </template>
          </van-cell>
        </van-grid-item>
        <van-grid-item use-slot>
          <van-cell center >
            <template #title>
              <div class="title_class">
                <span style="margin-right: 5px;">访客付费升级</span>
                <van-icon name="question" @click="showfangkeuplevel" />
              </div>
            </template>
            <template #right-icon>
              <van-switch
                v-model="fangkeuplevel"
                @change="setfangkeuplevel"
                :active-value="1"
                :inactive-value="0"
                active-color="#C82E30"
                size="20"
                :loading="fangkeuplevelload"
              />
            </template>
          </van-cell>
        </van-grid-item>
        <van-grid-item use-slot v-if="false">
          <van-cell center>
            <template #title>
              <div class="title_class">
                <span style="margin-right: 5px;">开启在线客服</span>
                <van-icon name="question" @click="showonlineKefu" />
              </div>
            </template>
            <template #right-icon>
              <van-switch
                v-model="onlinekefu"
                @change="setonlineKefu"
                :active-value="1"
                :inactive-value="0"
                active-color="#C82E30"
                size="20"
                :loading="onlinekefuload"
              />
            </template>
          </van-cell>
        </van-grid-item>
        <van-grid-item use-slot>
          <van-cell center>
            <template #title>
              <div class="title_class">
                <span style="margin-right: 5px;">分享展示店铺</span>
                <van-icon name="question" @click="showShopinfoTan" />
              </div>
            </template>
            <template #right-icon>
              <van-switch
                v-model="showShopinfo"
                @change="setShowShopinfo"
                :active-value="1"
                :inactive-value="0"
                active-color="#C82E30"
                size="20"
                :loading="onShowShopinfo"
              />
            </template>
          </van-cell>
        </van-grid-item>
        <van-grid-item use-slot>
          <van-cell center>
            <template #title>
              <div class="title_class">
                <span style="margin-right: 5px;">访客升合伙人</span>
                <van-icon name="question" @click="doAutoUpHHR" />
              </div>
            </template>
            <template #right-icon>
              <van-switch
                v-model="autoUpHHR"
                @change="setAutoUpHHR"
                :active-value="1"
                :inactive-value="0"
                active-color="#C82E30"
                size="20"
                :loading="onAutoUpHHR"
              />
            </template>
          </van-cell>
        </van-grid-item>
        <van-grid-item use-slot v-if="shouquShouyiShow == 1">
          <van-cell center>
            <template #title>
              <div class="title_class">
                <span style="margin-right: 5px;">{{shouquShouyiTitle}}</span>
                <van-icon name="question" @click="doGongXiangShouYi" />
              </div>
            </template>
            <template #right-icon>
              <van-switch
                v-model="gongXiangShouYi"
                @change="setGongXiangShouYi"
                :active-value="1"
                :inactive-value="0"
                active-color="#C82E30"
                size="20"
                :loading="onGongXiangShouYi"
              />
            </template>
          </van-cell>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="shop_btn_list">
      <van-cell :border="false" title-style="font-weight: bold;flex: 1;text-align: left;font-size: 16px;" title="常用功能" is-link></van-cell>
      <van-grid :column-num="4" :border="false">
        <van-grid-item :to="{name: 'Goodsaddprice'}" icon="setting-o" text="固定加价" />
        <van-grid-item @click="shopinfo" icon="shop-o" text="店铺设置" />
        <!-- <van-grid-item @click="upQunma" icon="upgrade" text="上传群码" /> -->
        <van-grid-item @click="upTuandui" icon="upgrade" text="店主微信" />
        <!-- <van-grid-item @click="upTuanduiFinished" icon="upgrade" text="完整介绍" /> -->
        <van-grid-item @click="shichang" icon="apps-o" text="联盟市场" />
        <van-grid-item @click="goods" icon="bag-o" text="商品管理" />
        <van-grid-item @click="member" icon="friends-o" text="客户管理" />
        <van-grid-item @click="getorder" icon="orders-o" text="订单管理" />
        <van-grid-item v-if="false" icon="logistics" text="供货管理" />
        <van-grid-item v-if="false" icon="free-postage" text="运费模板" />
        <van-grid-item v-if="false" icon="after-sale" text="售后处理" />
        <van-grid-item v-if="false" icon="service-o" text="客服设置" />
        <van-grid-item icon="edit" text="种子兑换" :to='{name: "ZhongziList"}' />
        <van-grid-item v-if="false" @click="account" icon="gold-coin-o" text="云店收益" />
        <van-grid-item :to="{name: 'Shantuantuan'}" icon="balance-o" text="闪团团收益" />
        <van-grid-item :to="{name: 'Opentuan'}" icon="description" text="开团申请" />
        <van-grid-item v-if="false" icon="bullhorn-o" text="营销工具" />
        <van-grid-item :to="{name: 'Statistics'}" icon="chart-trending-o" text="店铺数据" />
        <van-grid-item @click="todaka" icon="home-o" text="返回店铺" />
        <van-grid-item icon="desktop-o" text="装修首页" :to="{path: '/edit_shop'}" />
      </van-grid>
    </div>
    <div class="yundian_xueyuan" @click="todaxue">
      <van-cell :border="false" title-style="font-weight: bold;flex: 1;text-align: left;font-size: 16px;" title="云店学院" is-link></van-cell>
      <van-row gutter="10">
        <van-col span="12"><div class="col_list">使用教程</div></van-col>
        <van-col span="12"><div class="col_list">常见问题</div></van-col>
        <van-col span="12"><div class="col_list">进阶课程</div></van-col>
        <van-col span="12"><div class="col_list">官方讲座</div></van-col>
      </van-row>
    </div>
    <van-popup v-model="loadingshow" round :close-on-click-overlay="false">
      <div class="loading">
        <van-loading v-show="!shangjia" size="24px" vertical>上架中...</van-loading>
        <div v-show="shangjia">
          <van-icon name="checked" color="#07C160" size="24px" />
          <div>上架完成</div>
        </div>
        <div style="margin-top: 20px">
          <van-button block round @click="hideloading" :disabled="!shangjia" color="linear-gradient(to right, #ff6034, #ee0a24)">
            确定
          </van-button>
        </div>
      </div>
    </van-popup>
    <!-- 上传群码 -->
    <van-dialog v-model="UpQunMaShow" :title="uptitle" show-cancel-button @confirm="uploadingImg" :before-close="beforeClose"> <!-- 上传群码 -->
      <!-- <van-cell class="uploading_img">
        <template #title>
          <div v-if="uptype == 2" style="display: flex; align-content: center; justify-content: center; align-items: center;" @click="ShowUpShili">团队介绍示例<van-icon name="question" style="margin-left: 5px;" /></div>
          <div v-if="uptype == 2" style="margin-top: 5px; width: 150px;font-size: 10px;">
            请上传制作好的团队介绍<br />
            尺寸：680x405<br />
            群二维码会有客服生成并自动上传到会员资料中
          </div>
        </template>
        <template #default>
          <van-uploader v-model="fileList" accept="image/png, image/jpeg" :max-count="1" :max-size="2*1024*1024" :before-read="beforeRead" @oversize="onOversize" :after-read="afterRead" @delete="deleteTuanImg" />
        </template>
      </van-cell> -->
      <van-cell class="uploading_img">
        <template #title>
          <div v-if="uptype == 2" style="display: flex; align-content: center; justify-content: center; align-items: center;">个人微信二维码</div>
          <div v-if="uptype == 2" style="margin-top: 5px; width: 150px;font-size: 10px;">
            图片格式仅限png,jpg<br />
            其他格式无法保存
          </div>
        </template>
        <template #default>
          <van-uploader v-model="fileList2" accept="image/png, image/jpeg" :max-count="1" :max-size="2*1024*1024" :before-read="beforeRead2" @oversize="onOversize2" :after-read="afterRead2" @delete="deleteWxQrcode" />
        </template>
      </van-cell>
      <!-- <van-field v-if="uptype == 2" v-model="tuanduititle" show-word-limit maxlength="20" type="textarea" label="团队口号：" placeholder="20字以内" /> -->
      <van-radio-group v-model="showdjwx">
        <van-cell-group :border="false">
          <van-cell title-style="flex: 1;text-align: left;" title="只展示店主微信" clickable @click="showdjwx = 2">
            <template #default>
              <van-radio :name="2" />
            </template>
          </van-cell>
          <van-cell title-style="flex: 1;text-align: left;" title="展示上级微信" clickable @click="showdjwx = 1">
            <template #default>
              <van-radio :name="1" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { ImagePreview } from 'vant';
export default {
  name: 'Home',
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shoplevel: 3,
      isallgoods: 1, // 商品全部上架开关
      goodschecked: 0, // 商品自动上下架
      onlinekefu: 0, // 是否开启在线客服
      goodsallupload: false, // 商品自动上下架开关是否加载中
      goodscheckedload: false, // 商品自动上下架开关是否加载中
      onlinekefuload: false, // 是否开启在线客服开关是否加载中
      fangkeuplevelload: false, // 访客是否自主升为免考核顶级
      fangkeuplevel: 0, // 访客自主升为免考核顶级 默认关闭
      info: {}, // 用户信息
      order: {}, // 订单数据
      xcx: 0,
      loadingshow: false,
      shangjia: false,
      autoupLevel: 0,
      autoupLevelload: false, // 自动升级开关是否加载中
      memberNum: {}, // 统计会员人数
      page: '',
      UpQunMaShow: false, // 是否显示上传群吗
      fileList: [],
      uptitle: '上传群码',
      uptype: 1, // 1上传群吗 ，2 上传团队介绍
      uploadingSrc: '', // 上传成功的图片链接
      fileList2: [],
      uploadingSr2c: '',
      tuanduititle: '',
      tuanduicontent: '',
      onShowShopinfo: false,
      showShopinfo: 0,
      onAutoUpHHR: false,
      autoUpHHR: 0,
      shouquShouyiShow: 0,
      gongXiangShouYi: 0,
      gongXiangShouYiTitle: '',
      gongXiangShouYiTip: '',
      onGongXiangShouYi: false,
      // 展示顶级微信开关
      onShowdjwx: false,
      showdjwx: 1,
    }
  },
  mounted() {
    this.xcx = this.$route.query.xcx ? this.$route.query.xcx : 0;
    this.page = this.$route.query.page ? this.$route.query.page : '';
    localStorage.setItem("xcx", this.xcx);
    console.log('mounted');
    // this.init();
  },
  activated() {
    console.log('activated');
    this.init();
  },
  methods: {
    init() {
      console.log(this.screeHeight);
      console.log(this.$route.query.logincode);
      if (this.$route.query.logincode) {
        if (this.$route.query.merchid) {
          localStorage.setItem("SHOP_MID", this.$route.query.merchid);
        }
        this.autoLoing(this.$route.query.logincode);
      }else {
        this.getinfo();
      }
    },

    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/home/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.info = res.data.data.info;
            that.order = res.data.data.order;
            that.isallgoods = parseInt(res.data.data.info.isallgoods);
            that.goodschecked = parseInt(res.data.data.info.pushmerch);
            that.onlinekefu = parseInt(res.data.data.info.onlineKefu);
            that.autoupLevel = parseInt(res.data.data.info.autoupLevel);
            that.fangkeuplevel = parseInt(res.data.data.info.fangkeuplevel);
            that.showShopinfo = parseInt(res.data.data.info.showinfo);
            that.autoUpHHR = parseInt(res.data.data.info.autoup_hhr);
            that.gongXiangShouYi = parseInt(res.data.data.info.gongxian_shouyi);
            that.shouquShouyiShow = parseInt(res.data.data.info.shouqu_shouyi_show);
            that.shouquShouyiTitle = res.data.data.info.shouqu_shouyi_title;
            that.shouquShouyiTip = res.data.data.info.shouqu_shouyi_subtitle;
            that.memberNum = res.data.data.member;
            localStorage.setItem("SHOP_MID", res.data.data.info.id);
            localStorage.setItem("SHOP_MUID", res.data.data.info.memberid);
            that.showdjwx = parseInt(res.data.data.info.showdjwx);
            if(parseInt(res.data.data.info.show_wx_qrcode)){
              that.upTuandui();
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 开启完成任务自动升级按钮
    showLevelUp() {
      this.$dialog.alert({
        message: "开启后店铺锁粉会员完成任务后（第二天）会自动升级，关闭后，店铺锁粉会员，在会员中心也不会显示升级信息和保级信息，且完成任务后也不会升级。",
        theme: "round-button",
        className: "goods_checke_text",
        getContainer: ".shop_goods_switch",
        messageAlign: "left"
      }).then(() => {
        // on close
      });
    },
    // 上架全部商品
    showAllgoodsUp() {
      this.$dialog.alert({
        message: "点击刷新按钮上架全部商品。",
        theme: "round-button",
        className: "goods_checke_text",
        getContainer: ".shop_goods_switch",
        messageAlign: "left"
      }).then(() => {
        // on close
      });
    },
    // 商品自动上下架提示
    showgoodsChecked() {
      this.$dialog.alert({
        message: "此开关是分销市场商品自动上架开关，开启后分销市场新上架的商品将自动上架到您的店铺。",
        theme: "round-button",
        className: "goods_checke_text",
        getContainer: ".shop_goods_switch",
        messageAlign: "left"
      }).then(() => {
        // on close
      });
    },
    // 开启在线客服
    showonlineKefu() {
      this.$dialog.alert({
        message: "此开关是在线客服开关，开启后店铺将拥有在线客服功能。",
        theme: "round-button",
        className: "goods_checke_text",
        getContainer: ".shop_goods_switch",
        messageAlign: "left"
      }).then(() => {
        // on close
      });
    },
    // 访客升级
    showfangkeuplevel() {
      this.$dialog.alert({
        message: "此开关是控制您店铺会员可自主升为免考核顶级。",
        theme: "round-button",
        className: "goods_checke_text",
        getContainer: ".shop_goods_switch",
        messageAlign: "left"
      }).then(() => {
        // on close
      });
    },
    // 设置访客是否自主升级免考核顶级
    setfangkeuplevel(num) {
      console.log(num);
      let that = null;
      that = this;
      that.fangkeuplevelload = true; // 开启在线客服开关加载中
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/home/set_shop",
          that.$qs.stringify({
            type: 4, // 自动升级开关
            fangkeuplevel: that.fangkeuplevel
          })
        )
        .then(res => {
          console.log(res);
          that.fangkeuplevelload = false; // 开启在线客服开关加载结束
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "设置成功");
          }else {
            that.$toast(res.data.msg ? res.data.msg : "设置失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 设置商品全部上架
    setallgoodsup(num) {
      let that = null;
      that = this;
      if (num==2) {
        that.loadingshow = true,
        that.shangjia = false;
      } 
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/set_allgoods_up",
          that.$qs.stringify({
            type: 1,
            skipwhitelist: 1
          })
        )
        .then(res => {
          console.log(res);
          let str = "";
          if (num==2) {
            str = "全部上架";
            
          }else {
            str = "全部下架";
          }
          if (res.data.code == 100000) {
            that.info.isallgoods = num
            that.$toast(res.data.msg ? res.data.msg : str + "成功");
            if (num == 2) {
              that.shangjia = true;
            }
          }else {
            that.$toast(res.data.msg ? res.data.msg : str + "失败");
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    hideloading() {
      if (!this.shangjia) {
        this.$toast("正在上架中");
        return false;
      }
      this.loadingshow = false;
    },
    // 设置商品自动上下架
    setgoodschecked(num) {
      console.log(num);
      let that = null;
      that = this;
      that.goodscheckedload = true; // 商品自动上下架开关加载中
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/home/set_shop",
          that.$qs.stringify({
            type: 1,
            pushmerch: that.goodschecked
          })
        )
        .then(res => {
          console.log(res);
          that.goodscheckedload = false; // 商品自动上下架开关加载结束
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "设置成功");
          }else {
            that.$toast(res.data.msg ? res.data.msg : "设置失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 设置自动升级开关
    setautoupLevel(num) {
      console.log(num);
      let that = null;
      that = this;
      that.autoupLevelload = true; // 开启在线客服开关加载中
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/home/set_shop",
          that.$qs.stringify({
            type: 3, // 自动升级开关
            autoupLevel: that.autoupLevel
          })
        )
        .then(res => {
          console.log(res);
          that.autoupLevelload = false; // 开启在线客服开关加载结束
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "设置成功");
          }else {
            that.$toast(res.data.msg ? res.data.msg : "设置失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 设置在线客服开关
    setonlineKefu(num) {
      console.log(num);
      let that = null;
      that = this;
      that.onlinekefuload = true; // 开启在线客服开关加载中
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/home/set_shop",
          that.$qs.stringify({
            type: 2,
            onlineKefu: that.onlinekefu
          })
        )
        .then(res => {
          console.log(res);
          that.onlinekefuload = false; // 开启在线客服开关加载结束
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "设置成功");
          }else {
            that.$toast(res.data.msg ? res.data.msg : "设置失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    showShopinfoTan() {
      this.$dialog.alert({
        message: "此开关是控制用户分享时是否显示店铺信息的开关，关闭后用户分享时将不显示店铺信息。",
        theme: "round-button",
        className: "goods_checke_text",
        getContainer: ".shop_goods_switch",
        messageAlign: "left"
      }).then(() => {
        // on close
      });
    },

    setShowShopinfo(num) {
      console.log(num);
      let that = null;
      that = this;
      that.showShopinfo = num;
      that.onlinekefuload = true; // 开启在线客服开关加载中
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/home/set_shop",
          that.$qs.stringify({
            type: 5,
            showinfo: that.showShopinfo
          })
        )
        .then(res => {
          console.log(res);
          that.onlinekefuload = false; // 开启在线客服开关加载结束
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "设置成功");
          }else {
            that.$toast(res.data.msg ? res.data.msg : "设置失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 分享页面
    sharepage() {
      this.$router.push({
        name: "Shareshop",
        query: {
          t: new Date().getTime()
        }
      });
    },
    // 商户信息页面
    shopinfo() {
      this.$router.replace({
        name: "Myhome",
        query: {
          t: new Date().getTime()
        }
      });
    },
    // 账户资产页面
    account() {
      // this.$router.push({name: "Account"});
      this.$router.push({
        name: "Earning",
        query: {
          t: new Date().getTime()
        }
      });
    },
    // 客户管理
    member() {
      this.$router.push({
        name: "Member",
        query: {
          t: new Date().getTime()
        }
      });
    },
    // 商品管理
    goods() {
      this.$router.push({
        name: "Goodslist",
        query: {
          xcx: this.xcx,
          t: new Date().getTime()
        }
      });
    },
    getorder() {
      this.$router.replace({
        name: "Order",
        query: {
          xcx: this.xcx,
          t: new Date().getTime()
        }
      });
    },
    // 返回店铺
    todaka() {
      console.log(this.info);
      let mid = null;
      mid = localStorage.getItem("SHOP_MID")?localStorage.getItem("SHOP_MID"):0;
      if (this.xcx == 0) {
        window.location.href = "https://dk.midbest.cn/?shareid=" + this.info.memberid + "&merchid=" + mid;
      }else {
        window.wx.miniProgram.switchTab({
          url: "/pages/myhome/myhome"
        })
      }
      
    },

    todaxue() {
      let mid = null;
      mid = localStorage.getItem("SHOP_MID")?localStorage.getItem("SHOP_MID"):0;
      if (this.xcx == 0) {
        window.location.href = "https://dk.midbest.cn/videolist?shareid=" + this.info.memberid + "&merchid=" + mid;
      }else {
        window.wx.miniProgram.navigateTo({
          url: "/pages2/videolist/videolist"
        })
      }
    },
    // 市场
    shichang() {
      this.$router.replace({
        name: "About",
        query: {
          t: new Date().getTime()
        }
      });
    },
    autoLoing(logincode) {
      let that = null;
      let _pageQueryDt = null;
      that = this;
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/user/auto_login",
          that.$qs.stringify({
            logincode: logincode
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            localStorage.setItem("SHOP_UID", res.data.data);
            localStorage.setItem("SHOP_LOGIN", true);
            if(that.page && that.page.length > 1) {
              /* 跳转链接的参数 */
              _pageQueryDt = {};
              _pageQueryDt.xcx = that.xcx;
              _pageQueryDt.t = new Date().getTime();

              /* 跳转链接的参数 -- id */
              if(that.$route.query.id && parseInt(that.$route.query.id) > 0){
                _pageQueryDt.id = that.$route.query.id;
              }

              /* 跳转链接的参数 -- status */
              if(that.$route.query.status && parseInt(that.$route.query.status) > 0){
                _pageQueryDt.status = that.$route.query.status;
              }

              // that.$router.replace({
              //   name: "Earning",
              //   query: {
              //     t: new Date().getTime()
              //   }
              // });
              that.$router.replace({
                path: '/'+that.page,
                query: _pageQueryDt
              })
              return false;
            }
            that.$router.replace({
              name: "Home",
              query: {
                t: new Date().getTime()
              }
            });
            that.init();
          }else {
            that.$router.replace({
              name: "Login",
              query: {
                t: new Date().getTime()
              }
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    tolist() {
      if (this.xcx == 1) {
        window.wx.miniProgram.navigateTo({
          url: "/pages2/commission/index/index?hehuoren=1"
        })
      }
    },

    // 实名认证
    gorenzheng() {
      if (this.xcx == 1) {
        window.wx.miniProgram.navigateTo({
          url: "pages2/shiming/renzheng/renzheng"
        })
      }
    },
    // 上传群吗
    upQunma() {
      this.uptitle = "上传群码";
      this.uptype = 1;
      this.getUpinfo()
    },
    // 上传团队介绍图
    upTuandui() {
      this.uptitle = "上传个人微信二维码";
      this.uptype = 2;
      this.getUpinfo()
    },
    // 上传合成好的团队介绍
    upTuanduiFinished() {
      this.uptitle = "上传合成好的团队介绍";
      this.uptype = 3;
      this.getUpinfo()
    },
    // 获取上传示例
    ShowUpShili() {
      ImagePreview(['https://api.midbest.cn/uploads/logo/show650860.png?t=5']);
    },
    // 获取上传内容
    getUpinfo() {
      let that = null;
      that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 是否禁止背景点击
        message: '加载中...',
        overlay: true, // 是否显示背景遮罩层
      });
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/user/getGroupInfo")
        .then(res => {
          console.log(res);
          Toast.clear();
          if (res.data.code == 100000) {
            let imgsrc = "";
            imgsrc = res.data.data.qrcode && res.data.data.qrcode.length > 0 ? res.data.data.qrcode[0] : '';
            if (that.uptype == 2) {
              imgsrc = res.data.data.imgs && res.data.data.imgs.length > 0 ? res.data.data.imgs[0] : '';
            }else if (that.uptype == 3) {
              imgsrc = res.data.data.tuanImgFinished && res.data.data.tuanImgFinished.length > 0 ? res.data.data.tuanImgFinished : '';
            }
            that.fileList = [];
            that.uploadingSrc = '';
            that.tuanduititle = res.data.data.title ? res.data.data.title : '';
            that.tuanduicontent = res.data.data.content ? res.data.data.content : '';
            if (imgsrc.length > 10) {
              that.fileList = [{
                url: imgsrc,
                isImage: true
              }]
              that.uploadingSrc = imgsrc;
            }

            let imgsrc2 = "";
            imgsrc2 = res.data.data.wxqrcode && res.data.data.wxqrcode.length > 0 ? res.data.data.wxqrcode[0] : '';
            that.fileList2 = [];
            that.uploadingSrc2 = '';
            if (imgsrc2.length > 10) {
              that.fileList2 = [{
                url: imgsrc2,
                isImage: true
              }]
              that.uploadingSrc2 = imgsrc2;
            }
          }else {
            that.fileList = []
            that.uploadingSrc = '';
            that.fileList2 = []
            that.uploadingSrc2 = '';
            that.tuanduititle = '';
            that.tuanduicontent = '';
          }
          this.UpQunMaShow = true;
        })
        .catch(err => {
          console.log(err);
        })
    },
    //  上传文件格式判定
    beforeRead(file){
      if(file.type==='image/jpeg'||file.type==='image/png'||file.type==='image/jpg'){
        return true
      }
      Toast("仅支持上传PNG/JPG")
    },
    // 文件上传大小限制
    onOversize(file){
      Toast("图片大小不能超过2M")
    },
    // 上传图片
    afterRead(file) {
      let that = null;
      that = this;
      console.log(file);
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/shop/qunma');
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 是否禁止背景点击
        message: '上传中...',
        overlay: true, // 是否显示背景遮罩层
      });
      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        Toast.clear();
        if (response.data && response.data.code == 100000) {
          that.uploadingSrc = response.data.img_url;
          that.fileList = [{
            url: response.data.img_url,
            isImage: true
          }]
          Toast("上传完成")
        }
      }).catch( error =>{
        console.log(error);
      })
    },

    // 删除图片
    deleteTuanImg(file) {
      console.log(file);
      this.uploadingSrc = '';
    },
    //  上传文件格式判定
    beforeRead2(file){
      if(file.type==='image/jpeg'||file.type==='image/png'||file.type==='image/jpg'){
        return true
      }
      Toast("仅支持上传PNG/JPG")
    },
    // 文件上传大小限制
    onOversize2(file){
      Toast("图片大小不能超过2M")
    },
    // 上传个人微信二维码图片
    afterRead2(file) {
      let that = null;
      that = this;
      console.log(file);
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/shop/qunma');
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 是否禁止背景点击
        message: '上传中...',
        overlay: true, // 是否显示背景遮罩层
      });
      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        Toast.clear();
        if (response.data && response.data.code == 100000) {
          that.uploadingSrc2 = response.data.img_url;
          that.fileList2 = [{
            url: response.data.img_url,
            isImage: true
          }]
          Toast("上传完成")
        }
      }).catch( error =>{
        console.log(error);
      })
    },

    // 删除图片
    deleteWxQrcode(file) {
      console.log(file);
      this.uploadingSrc2 = '';
    },

    beforeClose(action, done) {
      if (action === 'confirm') {
        setTimeout(() => {
          done(false);
        }, 1000);
      } else {
        done();
      }
    },

    // 保存上传图片
    uploadingImg() {
      console.log("uploadingImg");
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/user/saveGroupInfo",
          that.$qs.stringify({
            type: that.uptype,
            imgs: [that.uploadingSrc],
            wxqrcode: [that.uploadingSrc2],
            title: that.tuanduititle,
            content: that.tuanduicontent,
            showdjwx: that.showdjwx
          })
        )
        .then(res => {
          console.log(res);
          that.onlinekefuload = false; // 开启在线客服开关加载结束
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "设置成功");
            that.UpQunMaShow = false;
          }else {
            that.$toast(res.data.msg ? res.data.msg : "设置失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 复制
    copy(text) {
      let _that = null;
      _that = this;
      _that.$copyText(text)
      .then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },

    doAutoUpHHR() {
      this.$dialog.alert({
        message: "此开关是控制您店铺会员可自主升为合伙人。",
        theme: "round-button",
        className: "goods_checke_text",
        getContainer: ".shop_goods_switch",
        messageAlign: "left"
      }).then(() => {
        // on close
      });
    },
    setAutoUpHHR(num) {
      console.log(num);
      let that = null;
      that = this;
      that.autoUpHHR = num;
      that.onAutoUpHHR = true; // 开启升级合伙人加载中
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/home/set_shop",
          that.$qs.stringify({
            type: 6,
            autouphhr: that.autoUpHHR
          })
        )
        .then(res => {
          console.log(res);
          that.onAutoUpHHR = false; // 开启升级合伙人加载中
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "设置成功");
          }else {
            that.$toast(res.data.msg ? res.data.msg : "设置失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    doGongXiangShouYi() {
      this.$dialog.alert({
        message: this.shouquShouyiTip,
        theme: "round-button",
        className: "goods_checke_text",
        getContainer: ".shop_goods_switch",
        messageAlign: "left"
      }).then(() => {
        // on close
      });
    },
    setGongXiangShouYi(num) {
      console.log(num);
      let that = null;
      that = this;
      that.gongXiangShouYi = num;
      that.onGongXiangShouYi = true; // 不收店主收益加载中
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/home/set_shop",
          that.$qs.stringify({
            type: 7,
            gongxianshouyi: that.gongXiangShouYi
          })
        )
        .then(res => {
          console.log(res);
          that.onGongXiangShouYi = false; // 不收店主收益加载中
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "设置成功");
          }else {
            that.$toast(res.data.msg ? res.data.msg : "设置失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 展示顶级微信开关
    setShowdjwx(num) {
      console.log(num);
      let that = null;
      that = this;
      that.onShowdjwx = true;
      that.showdjwx = num;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/home/set_shop",
          that.$qs.stringify({
            type: 8,
            showdjwx: that.showdjwx
          })
        )
        .then(res => {
          console.log(res);
          that.onShowdjwx = false; // 展示顶级微信开关加载中
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "设置成功");
          }else {
            that.$toast(res.data.msg ? res.data.msg : "设置失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },



  },
}
</script>
<style lang="less">
.home {
  background-color: #ECECEC;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  &:last-child {
    padding-bottom: 50px;
  }
  // 头部
  .header {
    padding: 20px 0 5px;
    background-color: #FF4F3C;
    overflow: hidden;
    .van-cell {
      color: #fff;
      background-color: transparent;
      .van-image {
        overflow: hidden;
        margin-right: 10px;
        border: 1px solid #fff;
      }
      .van-cell__title {
        flex: 1;
        text-align: left;
        overflow: hidden;
        .shop_name {
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          overflow: hidden;
        }
        .shop_data {
          margin-top: 4px;
          font-size: 12px;
          color: #F8FFFF;
          display: flex;
          align-items: center;
          overflow: hidden;
          .van-tag {
            background-color: transparent;
            margin-left: 5px;
          }
        }
        .van-notice-bar {
          height: 24px;
          border-radius: 6px;
          font-size: 12px;
        }
      }
      .right_list {
        overflow: hidden;
        .van-grid-item__content {
          background-color: transparent;
          color: #fff;
          padding: 0;
          .van-grid-item__text {
            color: #fff;
            margin-top: 0;
          }
        }
      }
    }
    .renzheng {
      margin: 5px 10px;
      .van-cell {
        background-color: #ffffff;
        padding: 5px 10px;
        border-radius: 8px;
        .van-cell__left-icon {
          color: #FF4F3C;
        }
        .van-cell__title {
          color: #707070;
        }
      }
    }
  }
  .order_data_main {
    // 订单项
    .order_list {
      margin: 10px;
      padding: 10px 0 0;
      overflow: hidden;
      background-color: #fff;
      border-radius: 5px;
      .van-cell {
        .van-cell__title,
        .van-cell__default {
          flex: 1;
        }
        .van-cell__title { 
          text-align: left;
          font-weight: bold;
        }
      }
      .van-grid {
        .van-grid-item__icon-wrapper {
          font-size: 20px;
          font-weight: 450;
        }
        .van-grid-item__text {
          margin-top: 8px;
        }
      }
    }
    // 今日数据
    .shop_day_data {
      background-color: #fff;
      border-radius: 5px;
      margin: 10px;
      padding: 10px 10px 0;
      overflow: hidden;
      .van-cell {
        padding: 10px 0;
        .van-cell__title {
          text-align: left;
          font-weight: bold;
          margin-right: 10px;
          font-size: 16px;
        }
        .van-cell__value {
          flex: 1;
          text-align: left;
        }
      }
      .van-grid {
        .van-grid-item {
          .van-grid-item__content {
            padding: 10px 8px 16px 0;
          }
          &:last-of-type {
            .van-grid-item__content {
              padding: 10px 0;
            }
          }
        }
        .grid_item_title,
        .grid_item_num {
          margin-bottom: 12px;
          line-height: 24px;
          font-size: 14px;
          color: #333;
        }
        .grid_item_num {
          font-weight: 450;
          font-size: 14px;
          font-weight: bold;
        }
        .grid_item_member_title,
        .grid_item_member_num {
          line-height: 24px;
          font-size: 12px;
          color: #333;
        }
        .grid_item_member_num {
          font-weight: 450;
        }
        .grid_item_text {
          font-size: 12px;
          color: #999;
        }
        .hei {
          color: #333;
        }
        .mb0 {
          margin-bottom: 0;
        }
      }
    }
  }
    
  // 商品自动上下架开关
  .shop_goods_switch {
    margin: 10px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    .van-cell {
      .van-cell__title,
      .van-cell__value {
        color: #333;
        flex: 1;
        text-align: left;
        overflow: hidden;
        .title_class {
          display: flex;
          align-items: center;
          overflow: hidden;
        }
      }
    }
    .goods_checke_text {
      .van-dialog__message {
        color: #666;
      }
    }
  }
  // 按钮列表
  .shop_btn_list {
    padding: 10px;
    margin: 10px;
    background-color: #fff;
    border-radius: 5px;
    .van-cell {
      padding: 10px 0;
    }
    .van-grid {
      .van-grid-item:nth-of-type(-n+4) {
        color: #EB5E40;
      }
      .van-grid-item:nth-of-type(n+4)~:nth-of-type(-n+8) {
        color: #379FF8;
      }
      .van-grid-item:nth-of-type(n+8)~:nth-of-type(-n+12) {
        color: #44B659;
      }
      .van-grid-item:nth-of-type(n+12)~:nth-of-type(-n+16) {
        color: orange;
      }
    }
  }
  .yundian_xueyuan {
    margin: 10px;
    overflow: hidden;
    border-radius: 10px;
    background-color: #fff;
    .van-row {
      margin: 10px;
      overflow: hidden;
      .col_list {
        background-color: #FAFAFA;
        line-height: 40px;
        margin-bottom: 10px;
        height: 40px;
        font-size: 12px;
        border-radius: 4px;
      }
    }
  }
  // 加载中弹框
  .loading {
    width: 240px;
    padding: 20px;
  }
  .hei {
    color: #333;
  }
  .uploading_img {
    .van-cell__value {
      flex: 1;
      text-align: right;
    }
  }
  .van-field__value {
    flex: 1;
  }
  .switch_btn_list{
    .van-cell{
        padding: 0px 4px;
    }
  }
}
</style>
