import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    domain: "https://api.midbest.cn/", // http://mytest.com/  https://api.midbest.cn/
    dakaurl: "https://dk.midbest.cn/"
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
